import { Box } from "@chakra-ui/react";
import Navbar from "../components/Navbar";
import Content from "../components/Content";
import Swiper from "../components/Swiper";
import Contact from "../components/Contact";
import Footer from "../components/Footer";
import { useSelector } from "react-redux";
import ScrollReveal from "scrollreveal";

export default function HomePage() {
  const srTop = ScrollReveal({
    duration: 1000,
    distance: "80px",
    origin: "bottom",
    delay: 200,
    reset: true,
  });
  const theme = useSelector((state) => state.theme.theme.theme);

  return (
    <>
      <Box bgColor={theme ? "#f7f7f7" : "black"}>
        <Box>
          <Navbar theme={theme} />
          <Box>
            <Content theme={theme} srTop={srTop} />
            <Swiper theme={theme} srTop={srTop} />
            <Contact theme={theme} />
            <Footer theme={theme} />
          </Box>
        </Box>
      </Box>
    </>
  );
}

import { Box, Image } from "@chakra-ui/react";
import React, { useState } from "react";

export default function ImageCover({ val, index }) {
  const [imageLoaded, setImageLoaded] = useState(false);
  return (
    <Box position="relative" className="loading-placeholder ">
      <Box border={"1px crimson solid"} top="3px" left="3px">
        <div
          style={{
            visibility: imageLoaded ? "visible" : "hidden",
          }}
        >
          <Image
            draggable="false"
            src={val.imageCover}
            onLoad={() => setImageLoaded(true)}
            alt={`Image ${index + 1}`}
          />
        </div>
      </Box>
    </Box>
  );
}

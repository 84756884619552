import { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import { useNavigate, useParams } from "react-router-dom";
import Instagram from "../assets/images/instagram-loginpage.png";
import InstagramHomePage from "../assets/images/instagram-homepage.png";
import InstagramProfilePage from "../assets/images/instagram-profilepage.png";
import CashierHomePage from "../assets/images/cashier-homepage.png";
import CashierLoginPage from "../assets/images/cashier-loginpage.png";
import BookStoreAdminDashboardPage from "../assets/images/bookstore-admindashboard.png";
import BookStoreAdminPage from "../assets/images/bookstore-adminpage.png";
import BookStoreAdminProductPage from "../assets/images/bookstore-adminproductpage.png";
import BookStoreCartPage from "../assets/images/bookstore-cartpage.png";
import BookStoreHomePage from "../assets/images/bookstore-homepage.png";
import BookStoreLoginPage from "../assets/images/bookstore-loginpage.png";
import BookStoreOrderDetail from "../assets/images/bookstore-orderdetail.png";
import BookStoreProfilePage from "../assets/images/bookstore-profilepage.png";
import BookStoreRegisterPage from "../assets/images/bookstore-registerpage.png";
import SpotifyHomePage from "../assets/images/spotify-homepage.png";
import SpotifyLoginPage from "../assets/images/spotify-loginpage.png";
import SpotifyRegisterPage from "../assets/images/spotify-registerpage.png";
import GameBlogAboutPage from "../assets/images/gameblog-about.png";
import GameBlogBlogPage from "../assets/images/gameblog-blog.png";
import GameBlogHomePage from "../assets/images/gameblog-homepage.png";
import WitherHomePage from "../assets/images/Wither-homepage.png";

import {
  Box,
  Button,
  Center,
  Flex,
  Icon,
  Image,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import SwiperDetail from "../components/SwiperDetail";
import { BsArrowLeft, BsGithub, BsGlobe } from "react-icons/bs";
import { AiFillEye } from "react-icons/ai";
import { useSelector } from "react-redux";
import Footer from "../components/Footer";
import Particles from "../components/Particles";

export default function ProjectPage() {
  const theme = useSelector((state) => state.theme.theme.theme);
  const [imageLoaded, setImageLoaded] = useState(false);
  const { id } = useParams();
  const modalDetail = useDisclosure();

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page whenever the route changes.
  }, []);
  const nav = useNavigate();
  const content = [
    {
      text: "Instagram Copy",
      imageCover: Instagram,
      images: [
        { image: Instagram, text: "Login Page" },
        { image: InstagramHomePage, text: "Home Page" },
        { image: InstagramProfilePage, text: "Profile Page" },
      ],
      description:
        "An Instagram copy followed with a working login,register,profile edit,post adding and like features",
      frameworks: "React, Chakra UI, Formik, Yup, Axios",
      github: "https://github.com/ThomasAlvin/Instagram",
    },

    {
      text: "Cashier App",
      imageCover: CashierHomePage,
      images: [
        { image: CashierHomePage, text: "Home Page" },
        { image: CashierLoginPage, text: "Login Page" },
      ],
      description:
        "A cashier app followed with login and calculator features. an app to make calculations, and is one of the small projects i contributed while working in a team",
      frameworks: "React, Chakra UI",
      github: "https://github.com/ThomasAlvin/MiniProjectPos",
    },
    {
      text: "Book Store",
      imageCover: BookStoreHomePage,
      images: [
        { image: BookStoreHomePage, text: "Home Page" },
        { image: BookStoreLoginPage, text: "Login Page" },
        { image: BookStoreRegisterPage, text: "Register Page" },
        { image: BookStoreProfilePage, text: "Profile Page" },
        { image: BookStoreOrderDetail, text: "Order Page" },
        { image: BookStoreCartPage, text: "Cart Page" },
        { image: BookStoreAdminDashboardPage, text: "Admin Page" },
        { image: BookStoreAdminPage, text: "Admin-Management Page" },
        { image: BookStoreAdminProductPage, text: "Admin-Product Page" },
      ],
      description:
        "A working book store with a login,register,email confirmation,password reset,CRUD addressmorders,stock for admins and user respectively, profile editing, order management for each branch and many more. truly one of the most complicated and detailed project i have been a part of with a working back end server and database",
      frameworks:
        "React, Chakra UI, Formik, Yup, Axios, Moment, React-Paginate, Axios, Bcrypt, Express, SweetAlert,etc",
      github: "https://github.com/purwadhikafullstack/jcwd020601",
      projectLink: "https://jcwd020601.purwadhikabootcamp.com/login",
    },

    {
      text: "Spotify Copy",
      imageCover: SpotifyHomePage,
      images: [
        { image: SpotifyHomePage, text: "Home Page" },
        { image: SpotifyLoginPage, text: "Login Page" },
        { image: SpotifyRegisterPage, text: "Register Page" },
      ],
      description:
        "A simple copy of spotify with a create playlist feature and a working and a working music player with volume adjustments,play/pause, and skip/rewind features. one of my oldest project while i was practicing my frontend skills",
      frameworks: "React, Chakra UI",
      github:
        "https://github.com/ThomasAlvin/JCWD0206-FRONTEND/tree/main/my-app",
    },
    {
      text: "Personal Game Blog",
      imageCover: GameBlogHomePage,
      images: [
        { image: GameBlogHomePage, text: "Home Page" },
        { image: GameBlogAboutPage, text: "About Page" },
        { image: GameBlogBlogPage, text: "Blog Detail Page" },
      ],
      description:
        "A Game Blog to publish and share written content in a form of an articles and posts",
      frameworks: "Next.JS, Chakra UI, BootStrap",
      github: "https://github.com/ThomasAlvin/GameBlog",
      projectLink: "https://thomsonblog.netlify.app/",
    },
    {
      text: "Wither (Weather App)",
      imageCover: WitherHomePage,
      images: [{ image: WitherHomePage, text: "Home Page" }],
      description:
        "A weather app to search for forecast of a corresponding city based on their date and latitude or longitude",
      frameworks: "Vue.js, Tailwind, Axios, Moment, SweetAlert",
      github: "https://github.com/ThomasAlvin/Weather-App",
      projectLink: "https://wither-app.netlify.app/",
    },
  ];

  return (
    <>
      <Navbar theme={theme} />
      <Particles />
      <Flex
        color={"crimson"}
        fontWeight={700}
        fontSize={"36px"}
        bgColor={theme ? "#f7f7f7" : "black"}
        pl={{ base: "20px", lg: "150px", xl: "150px" }}
        py={"60px"}
        brightness={"40%"}
      >
        <Flex flexDir={"column"} gap={"30px"}>
          <Flex position={"relative"}>
            <Button
              bgColor={theme ? "#f7f7f7" : "black"}
              onClick={() => nav("/")}
              color={"crimson"}
              border={"2px crimson solid"}
              alignItems={"center"}
              gap={"10px"}
              fontSize={"20px"}
              _hover={{
                bgColor: "crimson",
                color: "black",
                border: "1px black solid",
              }}
              fontWeight={"400"}
            >
              <Box pt={"5px"}>
                <Icon as={BsArrowLeft} />
              </Box>
              Go Back{" "}
            </Button>
          </Flex>

          <div
            style={{
              visibility: imageLoaded ? "visible" : "hidden",
            }}
          >
            <Image
              draggable="false"
              position={"relative"}
              cursor={"pointer"}
              onClick={modalDetail.onOpen}
              onLoad={() => setImageLoaded(true)}
              border={"5px crimson solid"}
              borderRadius={"20px"}
              w={{ base: "90%", lg: "700px", xl: "700px" }}
              src={content[id].imageCover}
            ></Image>
          </div>
          <Flex
            position={"relative"}
            alignItems={"center"}
            justifyContent={"space-between"}
            width={{ base: "90%", lg: "auto", xl: "auto" }}
          >
            <Box fontSize={{ base: "16px", lg: "32px", xl: "32px" }}>
              {content[id].text}
            </Box>
            <Button
              w={{ base: "140px", lg: "auto", xl: "auto" }}
              bgColor={theme ? "#f7f7f7" : "black"}
              border={"1px solid crimson"}
              color={"crimson"}
              onClick={modalDetail.onOpen}
              gap={"5px"}
              _hover={{
                bgColor: "crimson",
                color: "black",
                border: "1px black solid",
              }}
            >
              <Icon
                fontSize={{ base: "20px", lg: "24px" }}
                as={AiFillEye}
              ></Icon>
              <Box fontSize={{ base: "16px", lg: "24px" }}>Project Pages</Box>
            </Button>
          </Flex>

          <Flex
            wordBreak={"break-all"}
            width={{ base: "90%", lg: "700px", xl: "700px" }}
            pl={"20px"}
            position={"relative"}
            fontSize={"16px"}
          >
            {content[id].frameworks}
          </Flex>

          <Center
            position={"relative"}
            width={{ base: "90%", lg: "700px", xl: "700px" }}
            borderBottom={"2px solid crimson"}
            bgColor={theme ? "#f7f7f7" : "141414"}
            color={"crimson"}
            p={"20px"}
            fontSize={"16px"}
            fontWeight={"500"}
          >
            {content[id].description}
          </Center>
          <Flex
            wordBreak={"break-all"}
            width={{ base: "90%", lg: "700px", xl: "700px" }}
            pl={"20px"}
            position={"relative"}
            fontSize={"16px"}
            fontWeight={400}
            alignItems={"center"}
            gap={"10px"}
          >
            <Icon
              fontSize={"24px"}
              color={theme ? "black" : "white"}
              as={BsGithub}
            />

            <Link href={content[id].github} target="_blank">
              {content[id].github}
            </Link>
          </Flex>
          <Flex
            wordBreak={"break-all"}
            width={{ base: "90%", lg: "700px", xl: "700px" }}
            pl={"20px"}
            position={"relative"}
            fontSize={"16px"}
            fontWeight={400}
            alignItems={"center"}
            gap={"10px"}
          >
            {content[id].projectLink ? (
              <Flex alignItems={"center"} gap={"10px"}>
                <Icon
                  fontSize={"24px"}
                  color={theme ? "black" : "white"}
                  as={BsGlobe}
                />
                <Link href={content[id].projectLink} target="_blank">
                  {content[id].projectLink}
                </Link>
              </Flex>
            ) : (
              <Flex alignItems={"center"} gap={"10px"}>
                <Icon
                  fontSize={"24px"}
                  color={theme ? "black" : "white"}
                  as={BsGlobe}
                />
                Not deployed (link unavailable)
              </Flex>
            )}
          </Flex>
        </Flex>
        <Modal
          isCentered
          isOpen={modalDetail.isOpen}
          onClose={modalDetail.onClose}
        >
          <ModalOverlay />
          <ModalContent
            h={{ base: "400px", lg: "600px", xl: "600px" }}
            w={{ base: "380px", lg: "auto", xl: "auto" }}
            maxW={"900px"}
          >
            <ModalHeader
              color={"crimson"}
              bgColor={theme ? "f7f7f7" : "#212121"}
              border={"2px solid crimson"}
              fontWeight={700}
            >
              <Center fontSize={"24px"}>{content[id].text}</Center>
            </ModalHeader>
            <ModalCloseButton fontSize={"20px"} color={"crimson"} />
            <ModalBody
              borderX={"2px solid crimson"}
              borderBottom={"2px solid crimson"}
              h={{ base: "300px", lg: "500px", xl: "500px" }}
              bgColor={theme ? "f7f7f7" : "#212121"}
            >
              <SwiperDetail val={content[id]} theme={theme} />
            </ModalBody>
          </ModalContent>
        </Modal>
      </Flex>
      <Footer theme={theme} />
    </>
  );
}

import { Box, Flex, Icon, Image, Link } from "@chakra-ui/react";
import { AiFillHome } from "react-icons/ai";
import { HiLightBulb } from "react-icons/hi";
import {
  BsFillMoonStarsFill,
  BsFillPersonFill,
  BsGithub,
  BsSunFill,
} from "react-icons/bs";
import { BiSolidContact } from "react-icons/bi";
import { FaInstagram } from "react-icons/fa";
import Linkedin from "../assets/images/Linkedin-logo.png";
import { useDispatch, useSelector } from "react-redux";
export default function Navbar() {
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.theme.theme.theme);
  const NavbarItems = [
    { name: "Home", icon: AiFillHome, link: "/#introduction" },
    { name: "Skills", icon: BsFillPersonFill, link: "/#skills" },
    { name: "Projects", icon: HiLightBulb, link: "/#projects" },
    { name: "Contact Me", icon: BiSolidContact, link: "/#contact" },
  ];
  const gradientStyles = {
    backgroundImage:
      "linear-gradient(to right, #405DE6, #833AB4, #E1306C, #FD1D1D, #F56040, #FFD700)", // Instagram gradient color
    WebkitTextFillColor: "transparent", // Make text color transparent
    backgroundClip: "text",
    color: "white", // Set text color to the desired color
  };
  return (
    <>
      <Flex
        bgColor={theme ? "#f7f7f7" : "#141414"}
        zIndex={2}
        h={"60px"}
        className={theme ? "light-mode" : ""}
        borderBottom={"1px crimson solid"}
        position={"sticky"}
        top={0}
        justifyContent={"space-evenly"}
      >
        <Flex
          gap={"20px"}
          padding={"10px"}
          display={{ base: "none", lg: "flex", xl: "flex" }}
        >
          {NavbarItems.map((val) => {
            return (
              <Link href={val.link} style={{ textDecoration: "none" }}>
                <Flex
                  pr={"3px"}
                  whiteSpace={"nowrap"}
                  color={theme ? "black" : "grey"}
                  border={"3px solid transparent"}
                  className="animated-underline"
                  cursor={"pointer"}
                  fontWeight={600}
                  fontSize={{ base: "12px", lg: "18px", xl: "18px" }}
                  alignItems={"center"}
                  textAlign={"center"}
                  textDecor={"none"}
                >
                  <Flex>
                    <Icon fontSize={"28px"} pb={"4px"} as={val.icon}></Icon>
                  </Flex>
                  {val.name}
                </Flex>
              </Link>
            );
          })}
        </Flex>
        <Flex
          justifyContent={"center"}
          textAlign={"center"}
          alignItems={"center"}
          color={theme ? "black" : "#f0f0f0"}
          fontWeight={"900"}
          fontSize={{ base: "14px", lg: "16px", xl: "16px" }}
        >
          {" "}
          <Box
            fontFamily={"times new roman"}
            fontSize={{ base: "28px", lg: "32px", xl: "32px" }}
            color={"crimson"}
          >
            T
          </Box>
          homson
        </Flex>
        <Flex
          justifyContent={"center"}
          textAlign={"center"}
          alignItems={"center"}
          gap={"40px"}
          padding={"10px"}
          px={{ base: "10px", lg: "90px", xl: "90px" }}
        >
          <Flex
            w={"36px"}
            h={"36px"}
            justifyContent={"center"}
            marginBottom={"3px"}
          >
            <Link
              href="https://www.linkedin.com/in/thomas-alvin-yeo-4b1189251/"
              isExternal
            >
              <Image
                className="navbar-icons"
                color={"#0a66c2"}
                src={Linkedin}
                cursor={"pointer"}
              ></Image>
            </Link>
          </Flex>
          <Flex
            borderRadius={"100%"}
            w={"36px"}
            h={"36px"}
            justifyContent={"center"}
          >
            <Link href="https://www.instagram.com/thomas.a.y/" isExternal>
              <Icon
                as={FaInstagram}
                className="navbar-icons"
                cursor={"pointer"}
                border={"1px solid black"}
                color={theme ? "black" : "white"}
                borderRadius={"20%"}
                fontSize={"30px"}
                style={gradientStyles}
              />
            </Link>
          </Flex>
          <Flex w={"36px"} h={"36px"} justifyContent={"center"}>
            <Link href="https://github.com/ThomasAlvin" isExternal>
              <Icon
                cursor={"pointer"}
                fontSize={"30px"}
                className="navbar-icons"
                color={theme ? "black" : "white"}
                as={BsGithub}
              ></Icon>
            </Link>
          </Flex>
          <Flex w={"30px"} h={"30px"} justifyContent={"center"}>
            <Flex
              cursor={"pointer"}
              borderRadius={"100%"}
              className="navbar-icons"
              color={theme ? "black" : "white"}
              border={theme ? "1px solid black" : "1px solid white"}
              p={"4px"}
            >
              <Icon
                onClick={() => {
                  const theme2 = JSON.parse(localStorage.getItem("theme"));
                  localStorage.setItem("theme", JSON.stringify(!theme2));
                  dispatch({
                    type: "SET_THEME",
                    payload: { theme: !theme2 },
                  });
                }}
                fontSize={"20px"}
                as={theme ? BsFillMoonStarsFill : BsSunFill}
              ></Icon>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
}

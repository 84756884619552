import {
  Box,
  Button,
  Center,
  Flex,
  Icon,
  Image,
  Input,
  Textarea,
} from "@chakra-ui/react";
import * as Yup from "yup";
import YupPassword from "yup-password";
import imageMan from "../assets/images/man-developing-website-on-desk.png";
import { FiChevronsRight } from "react-icons/fi";
export default function Contact({ theme, setTheme }) {
  YupPassword(Yup);
  return (
    <>
      <Box
        pb={"100px"}
        color={"crimson"}
        pt={"50px"}
        gap={"40px"}
        display={"flex"}
        flexDir={"column"}
      >
        <Center
          zIndex={1}
          pb={"60px"}
          fontSize={{ base: "32px", lg: "50px" }}
          fontWeight={700}
          flexDir={"column"}
          fontFamily={"times new roman"}
          textAlign={"center"}
        >
          <Center>Send me a message!</Center>
          <Box color={theme ? "black" : "grey"} fontSize={"18px"}>
            you have any questions or offers for me?
          </Box>
          <Box color={theme ? "black" : "grey"} fontSize={"18px"}>
            or just to say hello? fill the form below
          </Box>
        </Center>
        <Center zIndex={1}>
          <Center
            p={"20px"}
            bgColor={theme ? "white" : "black"}
            border={"2px solid crimson"}
            borderRadius={"30px"}
          >
            <Image
              src={imageMan}
              draggable="false"
              display={{ base: "none", lg: "block" }}
              w={{ lg: "400px", xl: "500px" }}
            ></Image>
            <form
              name="contact"
              method="POST"
              netlify-honeypot="bot-field"
              data-netlify-recaptcha="true"
              data-netlify="true"
            >
              <input type="hidden" name="form-name" value="contact" />
              <Box
                flexDir={"column"}
                display={"flex"}
                pr={{ base: "0px", lg: "140px" }}
                px={{ base: "50px", lg: "0px" }}
              >
                <Center flexDir={"column"}>
                  <Flex flexDir={"column"}>
                    <Box>Don't fill this if you're human</Box>
                    <Input
                      id="name"
                      name="bot-field"
                      type="text"
                      placeholder=""
                      h={"50px"}
                      w={{ base: "auto", lg: "320px", xl: "320px" }}
                      focusBorderColor={"crimson"}
                      autocomplete="off"
                      borderColor={"crimson"}
                      variant={"flushed"}
                    ></Input>
                  </Flex>
                </Center>
                <Box>
                  <Box>Your Name</Box>
                  <Input
                    id="name"
                    name="name"
                    type="text"
                    placeholder="Ex : Tony"
                    h={"50px"}
                    // w={{ base: "120px", lg: "290px", xl: "290px" }}
                    focusBorderColor={"crimson"}
                    autocomplete="off"
                    borderColor={"crimson"}
                    variant={"flushed"}
                  ></Input>
                </Box>
                <Box>
                  <Box>Your Email</Box>
                  <Input
                    name="email"
                    id="email"
                    type="email"
                    placeholder="Ex : tony@gmail.com"
                    // w={{ base: "120px", lg: "290px", xl: "290px" }}
                    autocomplete="off"
                    h={"50px"}
                    borderColor={"crimson"}
                    focusBorderColor={"crimson"}
                    variant={"flushed"}
                  ></Input>
                  <Flex w={"100%"} fontSize={"12px"} color={"red"}></Flex>
                </Box>
                <Center flexDir={"column"}>
                  <Flex flexDir={"column"} w={"100%"}>
                    <Flex>Your Message</Flex>
                    <Textarea
                      name="message"
                      id="message"
                      h={"100px"}
                      placeholder="Ex : Hi Thomas, I just want to say, Have a nice day!"
                      resize={"none"}
                      borderColor={"crimson"}
                      w={{ base: "100%", lg: "320px", xl: "320px" }}
                      focusBorderColor="crimson"
                      variant={"flushed"}
                    ></Textarea>
                    <Flex w={"100%"} fontSize={"12px"} color={"red"}></Flex>
                  </Flex>
                </Center>
                <div data-netlify-recaptcha="true"></div>
                <Center pt={"20px"}>
                  <Button
                    type="submit"
                    alignItems={"center"}
                    gap={"10px"}
                    width={"160px"}
                    bgColor={theme ? "#f7f7f7" : "black"}
                    color={"crimson"}
                    border={"crimson 1px solid"}
                    _hover={{
                      bgColor: "crimson",
                      color: "black",
                      border: "1px black solid",
                    }}
                  >
                    <>
                      <Box>Submit Form</Box>
                      <Icon
                        className="contact-buttonText"
                        fontSize={"24px"}
                        as={FiChevronsRight}
                      ></Icon>
                    </>
                  </Button>
                </Center>
              </Box>
            </form>
          </Center>
        </Center>
      </Box>
    </>
  );
}

import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Icon,
  Link,
} from "@chakra-ui/react";
import { MdEmail } from "react-icons/md";
import { GiChameleonGlyph } from "react-icons/gi";
import { AiFillLinkedin } from "react-icons/ai";
import { BsGithub } from "react-icons/bs";
import { IoCall } from "react-icons/io5";
import { FaRegCopyright } from "react-icons/fa";

export default function Footer({ theme, setTheme }) {
  const Contacts = [
    {
      icon: MdEmail,
      text: "thomasalvinyeo@gmail.com",
    },
    {
      anchor: true,
      icon: AiFillLinkedin,
      text: "https://www.linkedin.com/in/thomas-alvin-yeo-4b1189251/",
    },
    {
      anchor: true,
      icon: BsGithub,
      text: "github.com/ThomasAlvin",
    },
    {
      icon: IoCall,
      text: "+62 82170795907",
    },
  ];
  return (
    <Box
      zIndex={2}
      bgColor={"crimson"}
      p={"20px"}
      px={{ base: "20px", lg: "100px", xl: "100px" }}
      color={theme ? "#f7f7f7" : "black"}
    >
      <Box>
        <Icon
          className="footer-chameleon"
          fontSize={"60px"}
          as={GiChameleonGlyph}
          color={"black"}
        ></Icon>
      </Box>
      <Flex
        pb={"50px"}
        gap={{ base: "10px", lg: "50px", xl: "50px" }}
        justifyContent={"space-around"}
      >
        <Flex
          w={"45%"}
          gap={"10px"}
          fontSize={{ base: "14px", lg: "20px", xl: "20px" }}
          fontWeight={400}
          flexDir={"column"}
        >
          <Center fontWeight={700} pb={"20px"}>
            Connect With Me!
          </Center>
          {Contacts.map((val) => (
            <Link
              gap={"10px"}
              display={"flex"}
              fontSize={{ base: "10px", lg: "16px", xl: "16px" }}
              href={val.text}
              pointerEvents={val.anchor ? "auto" : "none"}
            >
              <Icon
                fontSize={{ base: "20px", lg: "30px", xl: "30px" }}
                as={val.icon}
              ></Icon>
              <Box wordBreak={"break-word"}>{val.text}</Box>
            </Link>
          ))}
        </Flex>
        <Center
          fontWeight={"500"}
          w={"45%"}
          fontSize={{ base: "14px", lg: "20px", xl: "20px" }}
          flexDir={"column"}
          gap={"50px"}
        >
          <Flex>
            This web was built from scratch with the help of React, Chakra UI,
            Formik,Swiper, and Yup
          </Flex>
          <Flex
            justifyContent={"space-evenly"}
            gap={{ base: "10px", lg: "110px", xl: "110px" }}
          >
            <Link href="/#introduction">
              <Button
                bgColor={theme ? "#f7f7f7" : "black"}
                color={"crimson"}
                width={{ base: "50px", lg: "auto", xl: "auto" }}
                fontSize={{ base: "14px", lg: "16px", xl: "16px" }}
                border={"crimson 1px solid"}
                _hover={{
                  bgColor: "crimson",
                  color: "black",
                  border: "1px black solid",
                }}
              >
                Intro
              </Button>
            </Link>
            <Link href="/#skills">
              <Button
                bgColor={theme ? "#f7f7f7" : "black"}
                color={"crimson"}
                fontSize={{ base: "14px", lg: "16px", xl: "16px" }}
                width={{ base: "50px", lg: "auto", xl: "auto" }}
                border={"crimson 1px solid"}
                _hover={{
                  bgColor: "crimson",
                  color: "black",
                  border: "1px black solid",
                }}
              >
                Skills
              </Button>
            </Link>
            <Link href="/#projects">
              <Button
                bgColor={theme ? "#f7f7f7" : "black"}
                color={"crimson"}
                fontSize={{ base: "12px", lg: "16px", xl: "16px" }}
                border={"crimson 1px solid"}
                width={{ base: "50px", lg: "auto", xl: "auto" }}
                _hover={{
                  bgColor: "crimson",
                  color: "black",
                  border: "1px black solid",
                }}
              >
                Projects
              </Button>
            </Link>
          </Flex>
        </Center>
      </Flex>
      <Divider borderColor={"black"}></Divider>
      <Center pb={"20px"} alignItems={"center"} gap={"10px"} pt={"40px"}>
        <Icon as={FaRegCopyright}></Icon>
        <Box id="contact" fontSize={"16px"} fontWeight={500}>
          CopyRight 2023. Thomas Alvin Yeo. All Right Reserved
        </Box>
      </Center>
    </Box>
  );
}

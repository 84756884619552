import { useEffect } from "react";
import { useDispatch } from "react-redux";
export default function ThemeProvider({ children }) {
  const dispatch = useDispatch();

  useEffect(() => {
    /* eslint-disable react-hooks/exhaustive-deps */
    async function fetch() {
      const theme = JSON.parse(localStorage.getItem("theme"));
      if (theme) {
        dispatch({
          type: "SET_THEME",
          payload: { theme },
        });
      }
    }
    fetch();
  }, []);

  return (
    <>
      <>{children}</>
    </>
  );
}

const init = {
  theme: "",
};

function themeReducer(state = init, action) {
  if (action.type === "SET_THEME") {
    return {
      ...state,
      theme: action.payload.theme,
    };
  }
  return state;
}

export default themeReducer;

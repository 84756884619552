import {
  Box,
  Button,
  Center,
  Flex,
  Grid,
  GridItem,
  Icon,
  Image,
  Link,
  List,
  ListIcon,
  ListItem,
} from "@chakra-ui/react";
import fotoDiri from "../assets/images/Foto-diri-png.png";
import bloodMoon from "../assets/images/Bloodmoon.png";
import CV from "../assets/images/Thomas Alvin Yeo-CV.pdf";
import { AiFillCaretRight } from "react-icons/ai";
import { FaReact, FaVuejs } from "react-icons/fa";
import { GrMysql } from "react-icons/gr";
import {
  SiChakraui,
  SiExpress,
  SiNextdotjs,
  SiSequelize,
  SiTailwindcss,
} from "react-icons/si";
import { BiLogoJavascript, BiLogoNodejs } from "react-icons/bi";
import { AiFillHtml5 } from "react-icons/ai";
import { FaCss3Alt } from "react-icons/fa";
import { FiShare } from "react-icons/fi";
import { BsFillBootstrapFill } from "react-icons/bs";
import "swiper/css";
import Particles from "./Particles";
import { useEffect } from "react";
export default function Content({ theme, srTop }) {
  useEffect(() => {
    /* eslint-disable react-hooks/exhaustive-deps */
    const delayIncrement = 100; // Set your desired delay increment (in milliseconds)
    const revealCount = 20; // The number of elements to reveal

    for (let i = 1; i <= revealCount; i++) {
      srTop.reveal(`.sr-${i}`, { delay: i * delayIncrement });
    }
  }, []);
  const skills = [
    "Growth Mindset",
    "Cooperation",
    "Critical Thinking",
    "English Writing",
    "English Speaking",
    "English Listening",
    "Problem Solving",
    "Adaptibility",
    "Striving To Improve",
  ];
  const icons = [
    {
      name: "React",
      icon: FaReact,
      link: "",
    },
    {
      name: "JavaScript",
      icon: BiLogoJavascript,
      link: "",
    },
    {
      name: "HTML5",
      icon: AiFillHtml5,
      link: "",
    },
    {
      name: "CSS3",
      icon: FaCss3Alt,
      link: "",
    },
    {
      name: "Vue",
      icon: FaVuejs,
      link: "",
    },
    {
      name: "MySQL",
      icon: GrMysql,
      link: "",
    },
    {
      name: "Node.js",
      icon: BiLogoNodejs,
      link: "",
    },
    {
      name: "Sequelize",
      icon: SiSequelize,
      link: "",
    },
    {
      name: "Express.js",
      icon: SiExpress,
      link: "",
    },
    {
      name: "Chakra UI",
      icon: SiChakraui,
      link: "",
    },
    {
      name: "BootStrap",
      icon: BsFillBootstrapFill,
      link: "",
    },
    {
      name: "Tailwind CSS",
      icon: SiTailwindcss,
      link: "",
    },
    {
      name: "Next.js",
      icon: SiNextdotjs,
      link: "",
    },
  ];
  return (
    <>
      <Center pt={{ base: "30px", lg: "100px" }} id="introduction">
        <Flex flexDir={"column"}>
          <Flex display={{ base: "column", lg: "flex", xl: "flex" }}>
            <Flex
              mb={{ base: "30px", lg: "0px" }}
              position={"relative"}
              justifyContent={"center"}
              mx={{ base: "30px", lg: "20px", xl: "100px" }}
            >
              <Image
                className="fotoDiri sr-1"
                draggable="false"
                src={fotoDiri}
                w={{ base: "200px", lg: "300px", xl: "300px" }}
                h={{ base: "300px", lg: "400px", xl: "400px" }}
              ></Image>
              <Particles />
            </Flex>
            <Image
              draggable="false"
              src={bloodMoon}
              display={theme ? "none" : "block"}
              className="blood-moon"
            ></Image>

            <Flex
              position={"relative"}
              flexDir={"column"}
              color={theme ? "black" : "grey"}
              fontWeight={300}
              fontSize={"36px"}
            >
              <Flex
                className="sr-2"
                justifyContent={{ base: "center", lg: "normal" }}
              >
                <Flex
                  textAlign={"center"}
                  fontWeight={700}
                  color={"crimson"}
                  fontSize={"36px"}
                >
                  Hello I'm Thomas Alvin Yeo
                </Flex>
              </Flex>
              <Flex
                pb={"30px"}
                justifyContent={{ base: "center", lg: "normal" }}
              >
                <Flex className="sr-2">
                  <Box
                    className={theme ? "animated-text-light" : "animated-text"}
                    color={theme ? "black" : "grey"}
                  >
                    I'm a <span></span>
                  </Box>
                </Flex>
              </Flex>
              <Flex
                flexDir={"column"}
                gap={"20px"}
                alignItems={{ base: "center", lg: "normal" }}
              >
                <Flex
                  className="sr-3"
                  border={"2px crimson solid"}
                  bgColor={theme ? "white" : "black"}
                  p={"15px"}
                  fontWeight={"600"}
                  w={{ base: "350px", lg: "700px", xl: "700px" }}
                  fontSize={{ base: "10px", lg: "16px", xl: "16px" }}
                >
                  A fresh graduate at Purwadhika Digital Technology School in
                  Full Stack Web Developer who excel in English and all the
                  factors such as speaking, listening, and writing, I have
                  studied both front-end and back-end in website developing and
                  can adapt to mistakes and create according to the client's
                  needs and I'm looking forward to enhance my existing skills on
                  creating innovative solutions for the information technology
                  businesses.
                </Flex>
                <Flex gap={"30px"} alignItems={"center"} className="sr-4">
                  <Link href={CV} download>
                    <Button
                      className={`glow animated-fillColor-${
                        theme ? "light" : "dark"
                      }`}
                      border={"1px solid crimson"}
                      color={"crimson"}
                      gap={"10px"}
                    >
                      <Flex>Resume</Flex>
                      <Icon as={FiShare}></Icon>
                    </Button>
                  </Link>

                  <Link href="#contact">
                    <Button
                      className={`glow animated-fillColor-${
                        theme ? "light" : "dark"
                      }`}
                      border={"1px solid crimson"}
                      color={"crimson"}
                      gap={"10px"}
                    >
                      Contact
                    </Button>
                  </Link>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          <Flex id="skills"></Flex>
          <Flex
            zIndex={1}
            mt={"90px"}
            gap={"70px"}
            flexDir={"column"}
            onClick={() => console.log(theme)}
            color={theme ? "black" : "grey"}
          >
            <Flex
              justifyContent={"center"}
              color={"crimson"}
              fontWeight={700}
              fontSize={"36px"}
            >
              Hard Skills
            </Flex>
            <Flex
              className="sr-3"
              fontSize={{ base: "24px", lg: "50px", xl: "50px" }}
              textAlign={"center"}
              justifyContent={"center"}
            >
              <Flex
                justifyContent={"center"}
                gap={{ base: "10px", lg: "30px", xl: "30px" }}
                flexWrap={"wrap"}
              >
                {icons.map((val, index) => (
                  <Flex flexDir={"column"} className={`sr-${index}`}>
                    <Box className="skill-icons-box" cursor={"pointer"}>
                      <Icon className="skill-icons" as={val.icon}></Icon>
                      <Box className="skill-textbox">{val.name}</Box>
                    </Box>
                  </Flex>
                ))}
              </Flex>
            </Flex>
          </Flex>
          <Flex
            gap={"40px"}
            flexDir={"column"}
            mt={"90px"}
            w={"100%"}
            color={theme ? "black" : "grey"}
          >
            <Flex
              justifyContent={"center"}
              color={"crimson"}
              fontWeight={700}
              fontSize={"36px"}
            >
              Soft Skills
            </Flex>
            <Flex>
              <List
                pl={{ base: "30px", lg: "200px", xl: "200px" }}
                pr={{ base: "30px", lg: "200px", xl: "200px" }}
                w={"100%"}
              >
                <Grid
                  h={{ base: "250px", lg: "150px", xl: "150px" }}
                  w={"100%"}
                  justifyContent={"center"}
                  templateRows="repeat(5, 1fr)"
                  templateColumns="repeat(3, 1fr)"
                  gap={{ base: 2, lg: 4, xl: 4 }}
                  p={{ base: "10px", lg: "20px", xl: "20px" }}
                  fontSize={{ base: "12px", lg: "16px", xl: "16px" }}
                  fontWeight={600}
                >
                  {skills.map((val) => (
                    <GridItem colSpan={1}>
                      <ListItem gap={"0px"}>
                        <ListIcon as={AiFillCaretRight} color="crimson" />
                        {val}
                      </ListItem>
                    </GridItem>
                  ))}
                </Grid>
              </List>
            </Flex>
          </Flex>
          <Flex id="projects" name="projects"></Flex>
          <Flex
            flexDir={"column"}
            w={"100%"}
            color={"grey"}
            mt={{ base: "20px", lg: "90px" }}
          >
            <Flex
              justifyContent={"center"}
              color={"crimson"}
              fontWeight={700}
              fontSize={"36px"}
              pb={{ base: "20px", lg: "100px" }}
            >
              Projects
            </Flex>
          </Flex>
        </Flex>
      </Center>
    </>
  );
}

import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import { Navigation, Pagination } from "swiper/modules";
import { Center, Flex, Image } from "@chakra-ui/react";

export default function SwiperDetail({ val, theme }) {
  return (
    <>
      <Swiper
        style={{
          "--swiper-pagination-color": "crimson",
          "--swiper-navigation-color": "crimson",
          color: "white",
        }}
        pagination={{
          clickable: true,
        }}
        loop={true}
        navigation={true}
        modules={[Navigation, Pagination]}
      >
        {val.images.map((val) => (
          <>
            <SwiperSlide>
              <Center h={"100%"}>
                <Flex flexDir={"column"} h={"100%"}>
                  <Center
                    px={{ base: "0px", lg: "100px", xl: "100px" }}
                    position={"relative"}
                    h={"100%"}
                  >
                    <Image
                      w={{ base: "400px", lg: "700px", xl: "700px" }}
                      h={"100%"}
                      src={val.image}
                    ></Image>
                    <Center
                      position={"absolute"}
                      bottom={5}
                      left={0}
                      right={0}
                      bgColor={theme ? "white" : "#090909"}
                      h={"50px"}
                      opacity={0.7}
                      fontSize={"20px"}
                      fontWeight={"700"}
                      color={"crimson"}
                    >
                      {val.text}
                    </Center>
                  </Center>
                </Flex>
              </Center>
            </SwiperSlide>
          </>
        ))}
      </Swiper>
    </>
  );
}

import { Route } from "react-router-dom";
import HomePage from "../pages/HomePage";
import ProjectPage from "../pages/ProjectPage";
import ThemeProvider from "../hoc/themeProvider";

const routes = [
  <Route
    path="/"
    element={
      <ThemeProvider>
        <HomePage />
      </ThemeProvider>
    }
  ></Route>,

  <Route
    path="/:id"
    element={
      <ThemeProvider>
        <ProjectPage />
      </ThemeProvider>
    }
  ></Route>,
];
export default routes;

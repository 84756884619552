import Instagram from "../assets/images/instagram-loginpage.png";
import InstagramHomePage from "../assets/images/instagram-homepage.png";
import InstagramProfilePage from "../assets/images/instagram-profilepage.png";
import CashierHomePage from "../assets/images/cashier-homepage.png";
import CashierLoginPage from "../assets/images/cashier-loginpage.png";
import BookStoreAdminDashboardPage from "../assets/images/bookstore-admindashboard.png";
import BookStoreAdminPage from "../assets/images/bookstore-adminpage.png";
import BookStoreAdminProductPage from "../assets/images/bookstore-adminproductpage.png";
import BookStoreCartPage from "../assets/images/bookstore-cartpage.png";
import BookStoreHomePage from "../assets/images/bookstore-homepage.png";
import BookStoreLoginPage from "../assets/images/bookstore-loginpage.png";
import BookStoreOrderDetail from "../assets/images/bookstore-orderdetail.png";
import BookStoreProfilePage from "../assets/images/bookstore-profilepage.png";
import BookStoreRegisterPage from "../assets/images/bookstore-registerpage.png";
import SpotifyHomePage from "../assets/images/spotify-homepage.png";
import SpotifyLoginPage from "../assets/images/spotify-loginpage.png";
import SpotifyRegisterPage from "../assets/images/spotify-registerpage.png";
import GameBlogAboutPage from "../assets/images/gameblog-about.png";
import GameBlogBlogPage from "../assets/images/gameblog-blog.png";
import WitherHomePage from "../assets/images/Wither-homepage.png";
import GameBlogHomePage from "../assets/images/gameblog-homepage.png";
import { Box, Center, Flex, Grid, GridItem } from "@chakra-ui/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import "swiper/swiper-bundle.css";
import { useNavigate } from "react-router-dom";
import ImageCover from "./ImageCover";
import { useEffect } from "react";

export default function SwiperSkills({ theme, setTheme, srTop }) {
  useEffect(() => {
    /* eslint-disable react-hooks/exhaustive-deps */

    for (let i = 1; i <= 20; i++) {
      srTop.reveal(`.sr-${i}`, { delay: i * 100 });
    }
  }, []);
  const content = [
    {
      text: "Instagram Copy",
      imageCover: Instagram,
      images: [
        { image: Instagram, text: "Login Page" },
        { image: InstagramHomePage, text: "Home Page" },
        { image: InstagramProfilePage, text: "Profile Page" },
      ],
      description:
        "An Instagram copy followed with a working login,register,profile edit,post adding and like features",
      frameworks: "React,Chakra UI,Formik,Yup,Axios",
    },

    {
      text: "Cashier App",
      imageCover: CashierHomePage,
      images: [
        { image: CashierHomePage, text: "Home Page" },
        { image: CashierLoginPage, text: "Login Page" },
      ],
      description:
        "A cashier app followed with login and calculator features. an app to make calculate, and is one of the small projects i made while learning frontend",
      frameworks: "React,Chakra UI",
    },
    {
      text: "Book Store",
      imageCover: BookStoreHomePage,
      images: [
        { image: BookStoreHomePage, text: "Home Page" },
        { image: BookStoreLoginPage, text: "Login Page" },
        { image: BookStoreRegisterPage, text: "Register Page" },
        { image: BookStoreProfilePage, text: "Profile Page" },
        { image: BookStoreOrderDetail, text: "Order Page" },
        { image: BookStoreCartPage, text: "Cart Page" },
        { image: BookStoreAdminDashboardPage, text: "Admin Page" },
        { image: BookStoreAdminPage, text: "Admin-Management Page" },
        { image: BookStoreAdminProductPage, text: "Admin-Product Page" },
      ],
      description:
        "A working book store with a login, register, email confirmation, password reset, CRUD address, orders, stock for admins and user respectively, profile editing, order management for each branch. truly one of the most complicated and detailed project i have been a part of with a working back end server and database",
      frameworks:
        "React,Chakra UI,Formik,Yup,Axios,Moment,React-Paginate,Bcrypt,Express,SweetAlert,etc",
      link: "https://jcwd020601.purwadhikabootcamp.com/",
    },

    {
      text: "Spotify Copy",
      imageCover: SpotifyHomePage,
      images: [
        { image: SpotifyHomePage, text: "Home Page" },
        { image: SpotifyLoginPage, text: "Login Page" },
        { image: SpotifyRegisterPage, text: "Register Page" },
      ],
      description:
        "A simple copy of spotify with a create playlist feature and a working and a working music player with volume adjustments,play/pause, and skip/rewind features. one of my oldest project while i was practicing my frontend skills",
      frameworks: "React,Chakra UI",
    },
    {
      text: "Personal Game Blog",
      imageCover: GameBlogHomePage,
      images: [
        { image: GameBlogHomePage, text: "Home Page" },
        { image: GameBlogAboutPage, text: "About Page" },
        { image: GameBlogBlogPage, text: "Blog Detail Page" },
      ],
      description:
        "A Game Blog to publish and share written content in a form of an articles and posts",
      frameworks: "Next.JS,Chakra UI,BootStrap",
    },
    {
      text: "Wither (Weather App)",
      imageCover: WitherHomePage,
      images: [{ image: WitherHomePage, text: "Home Page" }],
      description:
        "A weather app to search for forecast of a corresponding city based on their date and latitude or longitude",
      frameworks: "Vue.js, Tailwind, Axios, Moment, SweetAlert",
    },
  ];
  const nav = useNavigate();
  return (
    <>
      <Center color={"crimson"}>
        <Grid
          templateColumns={{ base: "repeat(2, 1fr)", lg: "repeat(3, 1fr)" }}
          gap={{ base: "30px", lg: "60px" }}
          px={{ base: "20px", lg: "50px" }}
        >
          {content.map((val, index) => (
            <>
              <Box
                key={index}
                className={`image-container sr-${index + 1}`}
                onClick={() => nav("/" + index)}
              >
                <GridItem w={"100%"} h={"100%"}>
                  <Center position={"relative"} flexDir={"column"}>
                    <Box className="image-container2">
                      <ImageCover val={val} index={index} />
                      <Flex
                        className="projects-label"
                        pl={{ base: "0px", lg: "10px" }}
                        fontSize={{ base: "1rem", lg: "1.2rem" }}
                        height={{ base: "20%", lg: "15%" }}
                      >
                        {val.text}
                      </Flex>
                    </Box>
                    <Box
                      zIndex={-1}
                      w="100%"
                      h="100%"
                      left={{ base: "10px", sm: "20px" }}
                      border="3px solid crimson"
                      position="absolute"
                      top={{ base: "10px", sm: "20px" }}
                    ></Box>
                  </Center>
                </GridItem>
                <Box
                  display={{ base: "none", lg: "flex" }}
                  className="image-textbox"
                >
                  <p>{val.description}</p>
                </Box>
              </Box>
            </>
          ))}
        </Grid>
      </Center>
    </>
  );
}
